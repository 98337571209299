import { createRouter, createWebHistory } from 'vue-router';

const HomePage = () => import('../views/HomePage.vue');
const GamesPage = () => import('../views/GamesPage.vue');
const TrainingPage = () => import('../views/TrainingPage.vue');
const AboutUsPage = () => import('../views/AboutUsPage.vue');


const routes = [
    { path: '/', name: 'Home', component: HomePage },
    { path: '/lp/games', name: 'Games', component: GamesPage },
    { path: '/lp/training', name: 'Training', component: TrainingPage },
    { path: '/lp/about-us', name: 'About Us', component: AboutUsPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = 'TradeCruiser'
    next();
})

export default router;