<template>
    <header class="sticky top-0 z-50 h-[5.3125rem] p-4 mx-auto flex justify-center bg-white shadow-md">
        <div class="flex items-center justify-between w-full md:w-custom-wide md-max:w-custom-narrow">
            <a href="/"><img src="../assets/img/logo_dark.svg" alt=""></a>
            <!-- Burger icon, shown only on md and smaller -->
            <div @click="isMenuOpen = !isMenuOpen" class="sm-max:hidden">
                <img src="../assets/img/burger_icon.svg" alt="">
            </div>
            <div :class="{'hidden': !isMenuOpen, 'flex': isMenuOpen}" class="hidden sm-max:flex items-center">
                <nav class="px-10">
                    <router-link to="/lp/games"
                        class="p-4 mr-1 rounded-[10px] hover:bg-violet-primary  hover-trigger transition duration-300">
                        <svg width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="inline mr-2">
                            <rect width="30.326" height="18.3978" rx="9.19888" fill="#D380FE"
                                class="hover-target-white transition duration-300" />
                            <circle cx="23.4524" cy="7.07605" r="2.02173" fill="white" class="hover-target-orange" />
                            <circle cx="20.4192" cy="11.5239" r="2.02173" fill="white" class="hover-target-orange" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.09765 5.45868C8.42771 5.45868 7.88461 6.00178 7.88461 6.67172V7.98585H6.57046C5.90052 7.98585 5.35742 8.52895 5.35742 9.19889C5.35742 9.86883 5.90052 10.4119 6.57046 10.4119H7.88461V11.726C7.88461 12.396 8.42771 12.9391 9.09765 12.9391C9.7676 12.9391 10.3107 12.396 10.3107 11.726V10.4119H11.6248C12.2947 10.4119 12.8378 9.86883 12.8378 9.19889C12.8378 8.52895 12.2947 7.98585 11.6248 7.98585H10.3107V6.67172C10.3107 6.00178 9.7676 5.45868 9.09765 5.45868Z"
                                fill="#FEB484" class="hover-target-violet" />
                        </svg>
                        Games</router-link>
                    <router-link to="/lp/training"
                        class="p-4 mr-1 rounded-[10px] hover:bg-orange-primary hover-trigger transition duration-300">
                        <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="inline mr-2" alt="">
                            <rect x="7.70605" y="1" width="14.7647" height="12.5294" rx="1" stroke="#D380FE"
                                stroke-width="2" class="hover-target-stroke-white" />
                            <path
                                d="M0 13.6765C0 12.2958 1.11929 11.1765 2.5 11.1765H9.79412C11.1748 11.1765 12.2941 12.2958 12.2941 13.6765V18C12.2941 18.5523 11.8464 19 11.2941 19H0.999999C0.447715 19 0 18.5523 0 18V13.6765Z"
                                fill="white" />
                            <path
                                d="M1.02441 14.7941C1.02441 13.4134 2.1437 12.2941 3.52441 12.2941H8.76951C10.1502 12.2941 11.2695 13.4134 11.2695 14.7941V16.8824C11.2695 17.4346 10.8218 17.8824 10.2695 17.8824H2.02442C1.47213 17.8824 1.02441 17.4346 1.02441 16.8824V14.7941Z"
                                fill="#FEB484" class="hover-target-violet" />
                            <circle cx="6.14712" cy="8.38235" r="3.41176" fill="#FEB484" stroke="white"
                                class="hover-target-violet" />
                            <path
                                d="M14.5293 11.0588C14.5293 10.5066 14.977 10.0588 15.5293 10.0588H17.9999C18.5522 10.0588 18.9999 10.5066 18.9999 11.0588V12.2941H14.5293V11.0588Z"
                                fill="#D8EFBF" class="hover-target-violet" />
                        </svg>
                        Training</router-link>
                    <router-link to="/lp/about-us"
                        class="p-4 mr-1 rounded-[10px] hover:bg-green-primary hover-trigger transition duration-300">
                        <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                            class="inline mr-2">
                            <ellipse cx="22.7354" cy="10.6875" rx="6.75" ry="6.1875" fill="white"
                                class="hover-target-white" />
                            <ellipse cx="22.7354" cy="10.6875" rx="5.625" ry="5.0625" fill="#D8EFBF"
                                class="hover-target-orange" />
                            <ellipse cx="22.7354" cy="10.6875" rx="3.375" ry="3.9375" fill="white"
                                class="hover-target-white" />
                            <rect x="19.9229" y="6.75" width="5.625" height="11.25" rx="1" fill="#D8EFBF"
                                class="hover-target-orange" />
                            <circle cx="23.0166" cy="2.53125" r="2.53125" fill="#D8EFBF" class="hover-target-orange" />
                            <ellipse cx="6.98535" cy="10.6875" rx="6.75" ry="6.1875" fill="white" />
                            <ellipse cx="6.98535" cy="10.6875" rx="5.625" ry="5.0625" fill="#FEB484" />
                            <ellipse cx="6.98535" cy="10.6875" rx="3.375" ry="3.9375" fill="white" />
                            <rect x="4.17285" y="6.75" width="5.625" height="11.25" rx="1" fill="#FEB484" />
                            <circle cx="7.2666" cy="2.53125" r="2.53125" fill="#FEB484" />
                            <ellipse cx="14.8604" cy="10.6875" rx="6.75" ry="6.1875" fill="white" />
                            <ellipse cx="14.8604" cy="10.6875" rx="5.625" ry="5.0625" fill="#D380FE" />
                            <ellipse cx="14.8604" cy="10.6875" rx="3.375" ry="3.9375" fill="white" />
                            <rect x="12.0479" y="6.75" width="5.625" height="11.25" rx="1" fill="#D380FE" />
                            <circle cx="15.1416" cy="2.53125" r="2.53125" fill="#D380FE" class="hover-target-violet" />
                        </svg>

                        About Us</router-link>
                </nav>
                
                    <a @click="goToLogin()"
                        class="px-6 py-2  font-semibold rounded-[10px] border border-gray-800 hover:bg-green-300 transition duration-300">Login</a>
                

            </div>
        </div>
    </header>
</template>

<script>
    export default {
        data() {
            return {
                isMenuOpen: false,
            };
        },
        methods: {
            goToLogin() {
                window.location.href = '/login';
            }
        }
    };
</script>