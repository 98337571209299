<template>
  <div id="app">
    <HeaderBlock />
    <router-view />
    <FooterBlock v-if="!isLoginPage" />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import HeaderBlock from './components/HeaderBlock.vue';
import FooterBlock from './components/FooterBlock.vue';

export default {
  name: 'App',
  components: {
    HeaderBlock,
    FooterBlock
  },
  setup() {
    const route = useRoute();
    const isLoginPage = computed(() => route.name ==='Login');

    return {isLoginPage}
  }
};

</script>

