<template>
    <footer class="footer-bg">
        <div class="mx-auto w-full md:w-custom-wide md-max:w-custom-narrow flex mb-5 pt-40 ">
            <img src="../assets/img/logo_dark.svg" class="mr-20" alt="">
            <div class="flex items-center">
                <router-link to="/lp/training" class="mr-10 font-bold">About Us</router-link>
                <router-link to="/lp/games" class="mr-10 font-bold">Games</router-link>
                <router-link to="/lp/training" class="font-bold">Training</router-link>
            </div>
        </div>
        <span class="block border-t border-gray-300 mb-5"></span>
        <div class="mx-auto w-full md:w-custom-wide md-max:w-custom-narrow flex mb-10">
            <div class="flex flex-col mr-20">
                <p class="max-w-xs">Copyright © 2003-2024 TradeCruiser</p>
                <p class="mb-5 max-w-xs"> All rights reserved</p>
                <a href="/">Terms & conditions</a>
                <a href="/">Privacy & cookies policy</a>
            </div>
            <div>
                <p class="mb-5 font-bold">info@tradecruiser.com</p>
                <p class="mb-5 font-bold">+333 3333-3333</p>
                <p class="mb-5">10th Floor Bayfront Tower, Biscayne Boulevard, Miami, FL 33132, USA</p>
            </div>
        </div>

        <div class="mx-auto w-full md:w-custom-wide md-max:w-custom-narrow pb-20">
            <p>If you would like to find out more about which Revolut entity you receive services from, or if you have any
                other questions, please reach out to us via the in-app chat in the Revolut app. Revolut Ltd (No. 08804411)
                is authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 (Firm Reference
                900562). Registered address: 9th Floor 107 Cheapside, London, EC2V 6DN, United Kingdom. Insurance
                related-products are provided by Revolut Travel Ltd which is authorised by the Financial Conduct Authority
                to undertake insurance mediation services (FCA No: 780586) and by Revolut Ltd, an Appointed Representative
                of Revolut Travel Ltd in relation to insurance mediation services. Revolut Ltd is an Appointed
                Representative of Lending Works Ltd for the activity of “operating an electronic system for lending”.
                Trading and investment products are provided by Revolut Trading Ltd (No. 832790) is wholly owned subsidiary
                of Revolut Ltd and is an appointed representative of Sapia Partners LLP which is authorised and regulated by
                the Financial Conduct Authority.</p>
        </div>



    </footer>
</template>

<script>
export default {};
</script>